@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-slab-latin-400-normal.8336806e.woff2") format("woff2"), url("roboto-slab-latin-400-normal.c647e35f.woff") format("woff");
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-slab-latin-700-normal.90ce9bf6.woff2") format("woff2"), url("roboto-slab-latin-700-normal.7efceac6.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("open-sans-latin-400-normal.048d6aa3.woff2") format("woff2"), url("open-sans-latin-400-normal.fbf98ef1.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("open-sans-latin-700-normal.df41a2b6.woff2") format("woff2"), url("open-sans-latin-700-normal.f467ef79.woff") format("woff");
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #fff;
  background: #111;
  font-family: Open Sans;
}

a {
  color: #fff;
  outline: none;
  text-decoration: none;
}

strong, b {
  font-weight: bold;
}

h1 {
  letter-spacing: -1px;
  text-shadow: 0 0 8px #000;
  margin: 0;
  font-family: Roboto Slab, serif;
  font-size: 40px;
  line-height: 48px;
  position: relative;
}

h2 {
  text-shadow: 0 0 8px #000;
  margin: 20px 0;
  font-size: 18px;
  line-height: 30px;
}

h1 a, h2 a, h3 a {
  border-bottom: 1px dashed #fff;
}

input, textarea, button {
  color: #111;
  font-family: Open Sans;
}

input:focus, textarea:focus {
  box-shadow: none;
  outline: none;
}

.placeholder {
  color: #777;
}

::-webkit-input-placeholder {
  color: #777;
}

:-moz-placeholder-shown {
  color: #777;
}

.wrap {
  z-index: 1;
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 50px;
  position: relative;
}

#main {
  text-align: center;
  width: 100%;
  margin: 5em 0 0;
}

#getting-started {
  margin: 20px 0 30px;
}

#getting-started h2 {
  margin: 0 0 20px;
}

#getting-started * + #countdown {
  margin-top: 20px;
}

.no-slideshow #slides, .no-countdown #countdown {
  display: none !important;
}

.overlay-dotted .slides-container > div:before {
  content: "";
  z-index: 3;
  background: #0000004d url("dots.5ae44310.png");
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.overlay-dark .slides-container > div:before {
  content: "";
  z-index: 3;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.overlay-dark #video:before {
  content: "";
  z-index: 1;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.overlay-dotted #video:before {
  content: "";
  z-index: 1;
  background: #0000004d url("dots.5ae44310.png");
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

#video {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#slides {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
}

#slides .slides-container {
  display: none;
}

#slides .scrollable {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow-y: auto;
}

#slides .scrollable:after {
  content: "";
  clear: both;
  display: table;
}

.slides-navigation {
  display: none;
}

.slides-pagination {
  text-align: right;
  z-index: 3;
  width: auto;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.slides-pagination a {
  color: #0000;
  text-indent: -100%;
  vertical-align: middle;
  background: #fff;
  border: 4px solid #111;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  margin: 3px 0;
  transition: all .25s;
  display: block;
  overflow: hidden;
}

.slides-pagination a:hover {
  transform: scale(1.2);
}

.slides-pagination a.current {
  background: #cd2127;
}

.popup_background {
  z-index: 2000;
}

.popup_wrapper {
  z-index: 2001;
}

.popup_content {
  color: #777;
  background: #fff url("detail-top.168908b4.png") repeat-x;
  border-radius: 3px 3px 0 0;
  outline: none;
  padding-top: 3px;
  font-size: 14px;
  position: relative;
  box-shadow: 0 0 10px #0000004d;
}

.popup_content:after {
  content: "";
  z-index: -1;
  background: url("detail-top.168908b4.png") 0 100% repeat-x;
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.popup-title {
  color: #111;
  letter-spacing: -1px;
  padding: 16px 66px 16px 30px;
  font-family: Roboto Slab, serif;
  font-size: 28px;
  line-height: 1;
}

.popup-close-group {
  position: absolute;
  top: 18px;
  right: 18px;
}

.popup-close {
  color: #111333;
  cursor: pointer;
  background: #cd2127;
  border-radius: 2px;
  padding: 0;
  font-size: 20px;
  line-height: 1;
  display: block;
  position: absolute;
  top: 18px;
  right: 18px;
}

.popup-close:hover {
  color: #fff;
  background: #111;
}

.popup-close i {
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
}

.messages {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
  display: none;
}

.messages > * {
  border: 1px solid #0000001a;
  padding: 10px;
}

.messages .success {
  background: #c0d578;
}

.messages .error {
  background: #d95e5e;
}

#countdown {
  text-transform: uppercase;
  font-family: Roboto Slab;
  font-size: 14px;
}

#countdown > div {
  text-align: center;
  border-right: 1px dashed #ffffff73;
  margin-right: 20px;
  padding-right: 20px;
  display: inline-block;
}

#countdown > div:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}

#countdown span {
  margin-bottom: 5px;
  font-size: 48px;
  font-weight: bold;
  line-height: 48px;
  display: block;
}

#logo {
  text-align: center;
}

#logo img {
  max-width: 100%;
}

#links {
  float: right;
  display: inline-block;
}

#links:after {
  clear: both;
  content: "";
  display: table;
}

.button {
  color: #111;
  float: left;
  text-align: center;
  background: #cd2127;
  margin-right: 3px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: bold;
  line-height: 36px;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

.button:hover {
  color: #111;
  background: #fff;
}

.social-buttons {
  float: left;
}

.social-buttons .button {
  float: left;
  width: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: normal;
}

.social-buttons > :last-child {
  margin-right: 0;
}

#our_team {
  max-width: 900px;
  display: none;
}

#our_team .members {
  background: #111;
}

#our_team .members:after {
  clear: both;
  content: "";
  display: table;
}

#our_team .column-1 li {
  width: 100%;
}

#our_team .column-2 li {
  width: 50%;
}

#our_team .column-3 li {
  width: 33.33%;
}

#our_team .column-4 li {
  width: 25%;
}

#our_team .members li {
  float: left;
  box-shadow: inset 1px 0 #ffffff1a;
}

#our_team .members li:after {
  clear: both;
  content: "";
  display: table;
}

#our_team .members li:first-child {
  box-shadow: none;
}

#our_team .members .name {
  color: #fff;
  float: left;
  height: 30px;
  padding: 10px 0 10px 30px;
  font-size: 11px;
  display: block;
}

#our_team .members .name strong {
  color: #cd2127;
  text-align: left;
  margin: 0 0 5px;
  font-size: 13px;
  font-weight: bold;
  display: block;
}

#our_team .members .social {
  float: right;
  padding: 10px 30px 10px 0;
}

#our_team .members .social > a {
  color: #777;
  float: left;
  text-align: center;
  height: 30px;
  margin-right: 5px;
  font-size: 20px;
  line-height: 30px;
  transition: color .35s;
  display: block;
}

#our_team .members .social > a:hover {
  color: #cd2127;
}

#our_team .members .social > a:last-child {
  margin-right: 0;
}

#our_team .member-photo {
  clear: both;
}

#our_team .member-photo img {
  width: 100%;
  display: block;
}

#our_team .member-twitter {
  background: #fff;
  padding: 20px 30px;
}

#our_team .twitter-icon {
  color: #3eafdc;
  text-align: center;
  margin-bottom: 10px;
  font-size: 32px;
  position: relative;
}

#our_team .twitter-icon i {
  z-index: 2;
  background: #fff;
  position: relative;
}

#our_team .twitter-icon span {
  z-index: 1;
  border-top: 1px dotted #111;
  width: 32%;
  margin: 0 auto;
  display: block;
  position: relative;
  top: -15px;
}

#tweets li {
  color: #777;
  text-align: center;
  font-family: Georgia, Times New Roman, Times, serif;
  font-size: 18px;
  font-style: italic;
  line-height: 30px;
}

#tweets li a {
  color: #dc3e63;
}

#tweets .date {
  color: #a0a0a0;
  font-size: 12px;
}

#newsletter {
  max-width: 450px;
  display: none;
}

#newsletter .newsletter-info {
  color: #888;
  background: #111;
  padding: 20px 30px;
}

#newsletter .newsletter-info:after {
  clear: both;
  content: "";
  display: table;
}

#newsletter .newsletter-info i {
  color: #cd2127;
  float: left;
  background: #444;
  margin-right: 15px;
  font-size: 40px;
}

#newsletter .newsletter-info p {
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
}

#newsletter-form {
  padding: 20px 30px;
}

#newsletter-form > form:after {
  clear: both;
  content: "";
  display: table;
}

#newsletter-form input {
  float: left;
  background: #eee;
  border: 0;
  width: 75%;
  height: 40px;
  padding: 0 10px;
}

#newsletter-form button {
  color: #111;
  float: left;
  background: #cd2127;
  border: 0;
  width: 25%;
  height: 40px;
  font-size: 11px;
  font-weight: bold;
}

#newsletter-form button:hover {
  color: #fff;
  background: #111;
}

#contact {
  width: 900px;
  display: none;
}

#gmap {
  width: 100%;
  height: 300px;
  font-family: Open Sans, sans-serif;
}

#gmap .address-marker {
  width: 200px;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 20px;
  overflow: hidden;
}

#gmap .address-marker > * {
  margin-bottom: 10px;
  display: block;
}

#gmap .address-marker > strong {
  color: #222;
  font-family: Roboto Slab, serif;
  font-size: 13px;
  font-weight: 400;
}

#gmap .address-marker > .info {
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
}

#gmap .address-marker > .detail li {
  margin-bottom: 5px;
}

#gmap .address-marker > .detail li i {
  color: #222;
  float: left;
  margin-right: 5px;
  font-size: 14px;
  line-height: 16px;
  display: block;
}

#gmap .address-marker > .detail li span {
  color: #6ab7ab;
  font-family: Open Sans, sans-serif;
  font-size: 11px;
  line-height: 16px;
  display: block;
  overflow: hidden;
}

#gmap .ullist .active a {
  color: #000 !important;
}

#contact-form {
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 240px;
  padding: 15px;
  position: absolute;
  top: 90px;
  left: 30px;
  box-shadow: 0 1px 4px -1px #0000004d;
}

#contact-form form:after {
  clear: both;
  content: "";
  display: table;
}

#contact-form input {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0;
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-family: Open Sans;
  font-size: 12px;
}

#contact-form textarea {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  width: 100%;
  height: 65px;
  padding: 10px;
  font-family: Open Sans;
  font-size: 12px;
}

#contact-form button {
  float: left;
  color: #111;
  height: 30px;
  text-shadow: none;
  cursor: pointer;
  background: #cd2127;
  border: 0;
  border-radius: 2px;
  margin-top: 15px;
  padding: 0 15px;
  font-family: Open Sans;
  font-size: 11px;
  font-weight: bold;
}

#contact-form button:hover {
  color: #fff;
  background: #111;
}

#contact-form .form-loader {
  float: left;
  background: url("form_loader.a2c2dc91.gif") center no-repeat;
  width: 18px;
  height: 30px;
  margin-top: 15px;
  margin-left: 10px;
}

#contact-form .validatr-message {
  opacity: .8;
  white-space: nowrap;
  max-width: 250px;
  font-size: 11px;
  line-height: 20px;
}

.style-minimal {
  background: url("default_skin.c7b06683.jpg");
}

.style-minimal h2 a {
  color: #ffeba3;
  border-color: #ffeba3;
}

.style-minimal #slides, .style-minimal #video {
  display: none !important;
}

.orange-skin .button {
  color: #fff;
  background: #fe7435;
}

.orange-skin .button:hover {
  color: #111;
  background: #fff;
}

.orange-skin h2 a {
  color: #ffeba3;
  border-color: #ffeba3;
}

.orange-skin #our_team .members .social > a:hover, .orange-skin #our_team .members .name strong, .orange-skin #newsletter .newsletter-info i {
  color: #ffeba3;
}

.orange-skin .popup-close, .orange-skin #contact-form button, .orange-skin #newsletter-form button {
  background-color: #ffeba3;
}

.orange-skin #contact-form button:hover, .orange-skin #newsletter-form button:hover {
  background-color: #111;
}

.orange-skin .popup-close:hover {
  background: #111;
}

.orange-skin .slides-pagination a.current {
  background: #fe7435;
}

.style-minimal.orange-skin {
  background: url("orange_skin.978afd02.jpg");
}

.style-minimal.orange-skin h2 a {
  color: #ffeba3;
  border-color: #ffeba3;
}

.pink-skin .button {
  color: #fff;
  background: #e74262;
}

.pink-skin .button:hover {
  color: #111;
  background: #fff;
}

.pink-skin h2 a {
  color: #ffd1b2;
  border-color: #ffd1b2;
}

.pink-skin #our_team .members .social > a:hover, .pink-skin #our_team .members .name strong, .pink-skin #newsletter .newsletter-info i {
  color: #ffd1b2;
}

.pink-skin .popup-close, .pink-skin #contact-form button, .pink-skin #newsletter-form button {
  color: #fff;
  background-color: #ffd1b2;
}

.pink-skin #contact-form button:hover, .pink-skin #newsletter-form button:hover {
  background-color: #111;
}

.pink-skin .popup-close:hover {
  background: #111;
}

.pink-skin .slides-pagination a.current {
  background: #e74262;
}

.style-minimal.pink-skin {
  background: url("pink_skin.d8ee84cc.jpg");
}

.style-minimal.pink-skin h2 a {
  color: #ffd1b2;
  border-color: #ffd1b2;
}

.green-skin .button {
  color: #fff;
  background: #76bd0a;
}

.green-skin .button:hover {
  color: #111;
  background: #fff;
}

.green-skin h2 a {
  color: #fcffcc;
  border-color: #fcffcc;
}

.green-skin #our_team .members .social > a:hover, .green-skin #our_team .members .name strong, .green-skin #newsletter .newsletter-info i {
  color: #fcffcc;
}

.green-skin .popup-close, .green-skin #contact-form button, .green-skin #newsletter-form button {
  color: #111;
  background-color: #fcffcc;
}

.green-skin #contact-form button:hover, .green-skin #newsletter-form button:hover {
  color: #fff;
  background-color: #111;
}

.green-skin .popup-close:hover {
  color: #fff;
  background: #111;
}

.green-skin .slides-pagination a.current {
  background: #76bd0a;
}

.style-minimal.green-skin {
  background: url("green_skin.570fa3ca.png");
}

.style-minimal.green-skin h2 a {
  color: #fcffcc;
  border-color: #fcffcc;
}

.blue-skin .button {
  color: #fff;
  background: #3ea8e4;
}

.blue-skin .button:hover {
  color: #111;
  background: #fff;
}

.blue-skin h2 a {
  color: #ccebff;
  border-color: #ccebff;
}

.blue-skin #our_team .members .social > a:hover, .blue-skin #our_team .members .name strong, .blue-skin #newsletter .newsletter-info i {
  color: #ccebff;
}

.blue-skin .popup-close, .blue-skin #contact-form button, .blue-skin #newsletter-form button {
  color: #111;
  background-color: #ccebff;
}

.blue-skin #contact-form button:hover, .blue-skin #newsletter-form button:hover {
  color: #fff;
  background-color: #111;
}

.blue-skin .popup-close:hover {
  color: #fff;
  background: #111;
}

.blue-skin .slides-pagination a.current {
  background: #3ea8e4;
}

.style-minimal.blue-skin {
  background: url("blue_skin.ccfff0a0.jpg");
}

.style-minimal.blue-skin h2 a {
  color: #ccebff;
  border-color: #ccebff;
}

.style-minimal .button {
  color: #fff;
  background: #0000001a;
}

.style-minimal .button:hover {
  color: #111;
  background: #fff;
}

.site-center .wrap {
  text-align: center;
}

.site-center #main {
  width: 100%;
}

@media only screen and (width <= 769px) {
  .wrap {
    text-align: center;
  }

  #main {
    width: auto;
  }

  .popup_content {
    margin: 10px;
  }

  .slides-pagination {
    display: none;
  }

  #our_team .column-1 li, #our_team .column-2 li, #our_team .column-3 li, #our_team .column-4 li {
    width: 100%;
  }

  #contact-form {
    box-shadow: none;
    background: #f6f6f6;
    border: 0;
    width: auto;
    position: static;
  }
}

@media only screen and (width <= 480px) {
  h1 {
    font-size: 28px;
    line-height: 36px;
  }

  h2 {
    font-size: 15px;
    line-height: 24px;
  }

  .wrap {
    text-align: center;
    padding: 0 25px;
  }

  #main {
    margin-top: 50px;
  }

  .button {
    float: none;
    width: 100%;
    margin-bottom: 5px;
  }

  #our_team .column-1 li, #our_team .column-2 li, #our_team .column-3 li, #our_team .column-4 li {
    width: 100%;
  }

  #countdown {
    font-size: 13px;
  }

  #countdown > div {
    margin-right: 5px;
    padding-right: 5px;
  }

  #countdown span {
    font-size: 28px;
  }
}

#imprint {
  width: 630px;
  display: none;
}

#imprint .imprint-info {
  color: #fff;
  background: #111 url("wild_oliva_dark.3599952b.png");
  padding: 20px 30px 60px;
}

#imprint .imprint-info:after {
  clear: both;
  content: "";
  display: table;
}

#imprint .imprint-info .logo {
  color: #cd2127;
  float: left;
  background-color: #00000080;
  border-radius: 3px;
  margin-right: 25px;
  padding: 10px;
  font-size: 40px;
}

#imprint .imprint-info p {
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
}

#imprint .imprint-info strong {
  font-size: 16px;
  line-height: 20px;
}

#imprint .imprint-info > .detail li {
  margin-bottom: 5px;
}

#imprint .imprint-info > .detail li i {
  color: #888;
  float: left;
  width: 16px;
  min-height: 16px;
  margin-right: 5px;
  font-size: 14px;
  line-height: 16px;
  display: block;
}

#imprint .imprint-info > .detail li span {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 16px;
  display: block;
  overflow: hidden;
}

#policy {
  width: 900px;
  display: none;
}

#policy .policy-info {
  color: #fff;
  background: #111 url("wild_oliva_dark.3599952b.png");
  padding: 20px 30px 60px;
  line-height: 1.2;
}

#policy .policy-info h4 {
  margin: 1em 0;
  font-size: 1.2em;
}

#policy .policy-info:after {
  clear: both;
  content: "";
  display: table;
}
/*# sourceMappingURL=repo.b35017ab.css.map */
